import React from 'react';
import CardList from '../../components/CardList';

const jobs = [
  {
    title: 'Product Reliability Engineer',
    description: 'Currently working at Palantir, ensuring mission-critical products are available 24/7 by providing direct developer and user support to engineers and customers in solving challenging data problems.',
    layout: 'right',
    image: 'palantir.png',
    type: 'work',
    url: 'https://www.palantir.com',
    urlName: 'visit website',
  },
  {
    title: 'Software Engineering Intern',
    description: 'Worked on creating the initial version of the Effects microservice in C# and React. Additionally, led the squad through writing type-validating schema-based automation tests in Python.',
    layout: 'left',
    image: 'hudl.png',
    type: 'work',
    url: 'https://www.hudl.com',
    urlName: 'visit website',
  },
  {
    title: 'Senior Student Ambassador',
    description: 'Talking to prospective students in department open days while also helping them write Java code to control robots.',
    layout: 'right',
    image: 'nao.png',
    type: 'work',
    url: 'https://www.sheffield.ac.uk/dcs/undergraduate/student-stories/john',
    urlName: 'view profile',
  },
  {
    title: 'Teaching Assistant',
    description: 'Provided assistance to professors and students in laboratory exercises for the Java Programming module leading to a major improvement in debugging skills.',
    layout: 'left',
    image: 'ta.png',
    type: 'work',
  },
  {
    title: 'Software Engineering Intern',
    description: 'Worked as a fullstack software engineer where I developed on my skills in C#, .NET & Bootstrap by creating various mini projects.',
    layout: 'right',
    image: 'orascom.png',
    type: 'work',
    url: 'http://www.orascom.com',
    urlName: 'visit website',
  },
]

const work = () => {
  return (
    <CardList
      items={jobs}
      title="work experience" />
  );
};

export default work;

import React from 'react';
import CardList from '../../components/CardList';

const schools = [
  {
    title: 'University of Sheffield',
    description: 'Current third-year student studying Computer Science. Successfully won the Academic Middle East and Africa Undergraduate Merit Scholarship.',
    layout: 'left',
    image: 'unisheffield.png',
    type: 'education',
    url: 'https://www.sheffield.ac.uk/international/enquiry/studentprofiles/scholwinners/johnayad',
    urlName: 'view profile',
  },
]

const education = () => {
  return (
    <CardList
      items={schools}
      title="education" />
  );
};

export default education;

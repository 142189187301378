import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

import Layout from './components/Layout';
import MainPage from './pages/MainPage';
import NotFoundPage from './pages/NotFoundPage';

class App extends Component {
  render() {
    return (
      <Layout>
        <Switch>
          <Route exact path='/' component={MainPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </Layout>
    );
  }
}

export default App;

import React from 'react';

const socialProfile = (props) => {
  const { name, link, profile } = props;
  return (
    <a
      href={link + profile}
      target="_blank"
      rel="noopener noreferrer" >{name}.</a>
  );
};

export default socialProfile;

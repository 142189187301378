import React from 'react';
import CardList from '../../components/CardList';

const schools = [
  {
    title: 'hackHarassment',
    description: 'Successfully achieved the hackHarassment challenge set by MLH (Major Leage Hacking) in the hackSheffield 2.0 hackathon set in the University of Sheffield.',
    layout: 'right',
    image: 'hackharassment.png',
    type: 'awards',
  },
  {
    title: 'Go',
    description: 'Completed Udemy course covering all aspects of the Go Programming Language along with understanding the language specification.',
    layout: 'left',
    image: 'golang.png',
    url: 'https://www.udemy.com/certificate/UC-8VZD1CW3/',
    urlName: 'view certificate',
    type: 'awards',
  },
  {
    title: 'Node JS',
    description: 'Fully completed Node JS course on Udemy and created an API for a todo app using content and techniques learned from the course.',
    layout: 'right',
    image: 'nodejs.png',
    type: 'awards',
    url: 'https://www.udemy.com/certificate/UC-6BDLY34R/',
    urlName: 'view certificate',
  },
  {
    title: 'Engineering You\'re Hired!',
    description: 'Awarded distinction for research done to explore designing augment reality (AR) powered ice skiing goggles.',
    layout: 'left',
    image: 'eyh.png',
    type: 'awards',
    url: 'http://www.yourehired.group.shef.ac.uk/wp/',
    urlName: 'learn more',
  },
  {
    title: 'Global Engineering Challenge',
    description: 'Awarded distinction for research done throughout the week for ranking amongst top 10% across the faculty of engineering.',
    layout: 'right',
    image: 'gec.png',
    type: 'awards',
    url: 'https://www.sheffield.ac.uk/dcs/undergraduate/gec',
    urlName: 'learn more',
  },
]

const education = () => {
  return (
    <CardList
      items={schools}
      title="certificates &amp; awards" />
  );
};

export default education;

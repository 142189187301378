import React from 'react';
import classes from './index.css';

const about = () => (
  <section className={classes.contact}>
    <div className={classes.contactContent}>
      <h4>Want to get in touch? Email&nbsp;
        <a
          className={classes.email}
          href="mailto:me@johnayad.com">me@johnayad.com
        </a>
      </h4>
    </div>
  </section>
);

export default about;

import React, { Component } from 'react';
import classes from './index.css';
import Card from '../Card';

class CardList extends Component {
  render() {
    const { items, title } = this.props;
    return (
      <React.Fragment>
        <section className={classes.cardsTitle}>
          <h3>{title}</h3>
        </section>
        <div className={classes.cards}>
          {items.map((item) => (<Card {...item} key={item.image} />))}
        </div>
      </React.Fragment>
    );
  }
}

export default CardList;

import React from 'react';

import classes from './index.css';

const notFoundPage = () => (
  <section className={classes.notFound}>
    <h3>Lost?</h3>
    <p>
      Go back to the&nbsp;
      <a href="/">homepage</a>
    </p>
  </section>
);

export default notFoundPage;

import React from 'react';
import classes from './index.css';

const button = (props) => {
  const { text, url } = props;
  return (
    <a
      className={classes.button}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  )
};

export default button;

import React from 'react';
import classes from './index.css';

const about = () => (
  <section className={classes.about}>
    <div className={classes.aboutContent}>
      <p>
        This is my personal website to demonstrate my education, work experience, projects and interests.
      </p>
    </div>
  </section>
);

export default about;

import React from 'react';

import classes from './index.css';
import Header from '../../sections/Header';
import Footer from '../../sections/Footer';

const layout = (props) => (
  <React.Fragment>
    <Header />
    <div className={classes.wrapper}>
      {props.children}
    </div>
    <Footer />
  </React.Fragment>
);

export default layout;

import React from 'react';
import classes from './index.css';

const header = () => (
  <header>
    <div className={classes.intro}>
      <h1>john ayad</h1>
      <h2>software engineer</h2>
    </div>
  </header>
);

export default header;

import React from 'react';
import classes from './index.css';

import Button from '../Button';

const card = (props) => {
  const { title, description, layout, image, type, url, urlName } = props;
  const imagePath = "/images/" + type + "/" + image;
  let imageClasses = [classes.imageArea];
  if (layout === 'right') {
    imageClasses.push(classes.right);
  } else {
    imageClasses.push(classes.left);
  }
  return (
    <div className={classes.card}>
      <div className={imageClasses.join(' ')}>
        <img
          alt={[title, 'logo'].join(' ')}
          src={imagePath} />
      </div>
      <div className={classes.textBox}>
        <div className={classes.textBoxContent}>
          <h3>{title}</h3>
          <p>{description}</p>
          {url &&
            (<Button
                text={urlName}
                url={url} />
            )
          }
        </div>
      </div>
    </div>
  );
}

export default card;

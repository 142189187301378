import React from 'react';

import About from '../../sections/About';
import Education from '../../sections/Education';
import Work from '../../sections/Work';
import Projects from '../../sections/Projects';
import Awards from '../../sections/Awards';
import Hobbies from '../../sections/Hobbies';
import Contact from '../../sections/Contact';

const mainPage = () => (
  <React.Fragment>
    <About />
    <Work />
    <Education />
    <Projects />
    <Awards />
    <Hobbies />
    <Contact />
  </React.Fragment>
);

export default mainPage;

import React from 'react';
import CardList from '../../components/CardList';

const projectsList = [
  {
    title: 'Shipment Manager',
    description: 'Built a set of microservices in Go to exemplify usage of the microservices design pattern along with containerization technologies like Docker & Kubernetes.',
    layout: 'right',
    image: 'shipment-manager.png',
    type: 'projects',
  },
  {
    title: 'Programming Assignments Grader',
    description: 'Architected & developed an automatic web-based assignments grader in Go, Docker & React that supports 29 different programming langues & provides a language-agnostic extensible & flexible framework for grading.',
    layout: 'left',
    image: 'grader.png',
    type: 'projects',
  },
  {
    title: 'Information Retriever',
    description: 'Python-based search engine that retrieves query results in a fraction of a second. Utilizes techniques ranging from maintaining a reversed index to term weighting variations (Boolean/Term Frequency (TF)/Term Frequency Inverse Document Frequency (TF.IDF))',
    layout: 'right',
    image: 'search.png',
    type: 'projects',
  },
  {
    title: 'VoiceFX',
    description: 'Live and pre-recorded voice changer built in Pure Data with various effects and presets.',
    layout: 'left',
    image: 'voicefx.png',
    type: 'projects',
  },
  {
    title: 'Christian Orthodox Society',
    description: 'Fully redesigned and developed the society\'s website from scratch to run using ReactJS and follow industry standards.',
    layout: 'right',
    image: 'cos.png',
    type: 'projects',
  },
  {
    title: 'Sheffield Working Women Opportunities Project',
    description: 'Architected & implemented a Data-capturing & statistics generating web app that fully reinvents and optimizes work flow for the SWWOP charity.',
    layout: 'left',
    image: 'swwop.png',
    type: 'projects',
    url: 'http://www.swwop.org/',
    urlName: 'visit website',
  },
  {
    title: 'Dental Clinic App',
    description: 'Java-based Swing Application demonstrating Java, Swing, Git & UML knowledge. The projects is meant to model an appointments and patients management application for a dental clinic.',
    layout: 'right',
    image: 'dentalClinic.png',
    type: 'projects',
    url: 'https://github.com/johnhany97/dental-clinic-system',
    urlName: 'view on github',
  },
  {
    title: 'Puzzlemania',
    description: 'Website created for kids. Project shows HTML5, CSS3 & Responsive web design knowledge. The entire project was created in less than 24 hours and didn\'t use any external libraries like Bootstrap.',
    layout: 'left',
    image: 'puzzlemania.png',
    type: 'projects',
    url: 'https://puzzle.johnayad.com',
    urlName: 'view demo',
  },
  {
    title: 'Tweetza',
    description: 'Website project thats shows Ruby on rails, HTML5, CSS3, Bootstrap & Responsive web design knowledge. The project features an ordering system where users can order by tweeting and admins can view orders and manage restaurant menu items for seperate branches.',
    layout: 'right',
    image: 'tweetza.png',
    type: 'projects',
  },
  {
    title: 'ReVolt ROM',
    description: 'Custom Android-based open-source ROM distribution. Fully maintained for over 30 devices across thousands of users for a period of over two years.',
    layout: 'left',
    image: 'revolt.png',
    type: 'projects',
    url: 'https://github.com/ReVolt-ROM',
    urlName: 'view on github',
  },
]

const projects = () => {
  return (
    <CardList
      items={projectsList}
      title="projects" />
  );
};

export default projects;

import React from 'react';
import classes from './index.css';
import SocialProfiles from '../../components/SocialProfiles';

const footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer>
      <div className={classes.footerContent}>
        <p className={classes.credit}>
          &copy; Copyright {year} John Ayad.<br />
          Software Engineer
        </p>
        <SocialProfiles />
      </div>
    </footer>
  );
};

export default footer;

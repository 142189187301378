import React from 'react';
import CardList from '../../components/CardList';

const hobbiesList = [
  {
    title: 'Piano',
    description: 'Been playing the piano for over 15 years and performed in countless concerts including Gauargi Festival in France where I was part of the group representing Egypt.',
    layout: 'left',
    image: 'piano.png',
    type: 'hobbies',
  },
  {
    title: 'Scouts',
    description: 'Scouts member in St Markos Scouts since Grade 4. Led two scout troops and assisted in organising two scouting camps. Additionally, created the current scouts website using WordPress.',
    layout: 'right',
    image: 'scouts.png',
    type: 'hobbies',
    url: 'http://www.stmarkos-scout.org',
    urlName: 'visit website',
  },
]

const hobbies = () => {
  return (
    <CardList
      items={hobbiesList}
      title="hobbies" />
  );
};

export default hobbies;

import React from 'react';
import SocialProfile from '../SocialProfile';
import classes from './index.css';

const profiles = [
  {
    name: 'twitter',
    link: 'https://twitter.com/',
    profile: 'johnhayad'
  },
  {
    name: 'linkedin',
    link: 'https://linkedin.com/in/',
    profile: 'john-h-ayad'
  },
  {
    name: 'github',
    link: 'https://github.com/',
    profile: 'johnhany97'
  }
];

const socialProfiles = () => (
  <ul className={classes.social}>
    {profiles.map((profile) => (<li key={profile.name}><SocialProfile {...profile} /></li>))}
  </ul>
)

export default socialProfiles;
